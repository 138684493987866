import React from 'react'
import {InfoSec, InfoRow, TextColumn, TextWrapper, TopLine, Heading, Subtitle, InfoColumn} from './InfoInfoSection.elements'
import { Container} from '../../globalStyles'

 const InfoInfoSection = ({ 
    
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    thirdDescription,
    headline,
    description,
    secondDescription,
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={false}>
                        <TextColumn>
                            <TextWrapper>
                            <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                            <Subtitle lightTextDesc={lightTextDesc}>{secondDescription}</Subtitle>
                            <Subtitle lightTextDesc={lightTextDesc}>{thirdDescription}</Subtitle>
                            </TextWrapper>
                        </TextColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoInfoSection;