// ContactUsForm.js

import React, { useState } from 'react';
import './ContactForm.css';
import { Button } from '../../globalStyles';
import emailjs from '@emailjs/browser';
import emailConfig from './emailConfig.json';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [disabled, setDisabled] = useState(false);

  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: '',
    type: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    // Hide alert after 5 seconds
    setTimeout(() => {
      setAlertInfo({ display: false, message: '', type: '' });
    }, 5000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.info('Form submitted:', formData);

    // Destrcture data object
    const { name, email, subject, message } = formData;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      // Use emailjs to email contact form data
      emailjs.init(emailConfig.USER_ID)

      await emailjs.send(
        emailConfig.SERVICE_ID,
        emailConfig.TEMPLATE_ID,
        templateParams,
      );

      // Display success alert
      toggleAlert('Form submission was successful!', 'success');
    } catch (e) {
      console.error(e);
      // Display error alert
      toggleAlert('Uh oh. Something went wrong.', 'danger');
    } finally {
      // Re-enable form submission
      setDisabled(false);
      // Reset contact form fields after submission
      setFormData({ name: '', subject: '', email: '', message: '' });
    }
  };


  const handleSubmit1 = (e) => {
    e.preventDefault();
    // Add your logic here to handle the form submission, such as sending an email or making an API call
    console.log('Form submitted:', formData);




    // You can clear the form fields after submission if needed
    setFormData({ name: '', subject: '', email: '', message: '' });
  };

  return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
        <label>
          Name:
          <input type="text" name="name" className='form-control formInput' value={formData.name} placeholder='Name' onChange={handleChange} required />
        </label>
        </div>
        <div className="form-group">
          <input type="email" name="email" className='form-control formInput' value={formData.email} placeholder='Email' onChange={handleChange} required />
        </div>
        <div className="form-group">
          <input type="subject" name="subject" className='form-control formInput' value={formData.subject} placeholder='Subject' onChange={handleChange} required />
        </div>
        <div className="form-group">
          <textarea name="message" value={formData.message} className='form-control formInput' placeholder='Message' onChange={handleChange} required />
        </div>      
        <Button big fontBig  visible="true" primary="true" type="submit">
                                Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
