import React from 'react';
import { AboutUsDetails } from './Data';
import ContactUsSection from '../../components/FormSection/ContactUsSection';

const ContactUs = () => {
    return (
        <>
        <ContactUsSection {...AboutUsDetails}></ContactUsSection>            
        </>
    )
}

export default ContactUs;