const List = () => {
    const details = [
        'Stainless steel',
        'Sapphire crystal', 
        'Mesh steel band', 
        'Rotating silver dial', 
        'Miyota quartz movement', 
        '33mm size',
        '3ATM water resistant'
      ];

      const listItems = details.map(detail => <li key={detail}>{detail}</li>);
  
      return <ul>{listItems}</ul>;
  };
  
  export default List;