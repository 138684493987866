
import React from 'react';
import './HamburgerIcon1.css';

const HamburgerIcon1 = ({ isOpen, onClick }) => {
  return (
    <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={onClick}>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};

export default HamburgerIcon1;
