import React from 'react'
import { InfoSec, InfoRow, TextColumn, InfoColumn, TextWrapper, TopLine, Heading, SubtitleShort, Subtitle1, ImgWrapper, Img } from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

const InfoSectionBase = ({

    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    description1,
    buttonLabel,
    img,
    alt,
    imgStart,
    start
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <TextColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <SubtitleShort lightTextDesc={lightTextDesc}>{description}</SubtitleShort>
                                <Link to='/'>
                                    <Button big fontBig visible={buttonLabel != null} primary={primary}>
                                        {buttonLabel}
                                    </Button>
                                </Link>
                                <Subtitle1 lightTextDesc={lightTextDesc}>{description1}</Subtitle1>

                            </TextWrapper>

                        </TextColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={img} alt={alt} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSectionBase;