export const homeObjOne = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Unisex',
  headline: 'Elusive Pendant',
  description:
    'Stay present, stay connected. Assistance at your fingertips.',
  description1: '*An iPhone bluetooth accessory',
  buttonLabel: 'Coming soon',
  imgStart: '',
  img: require('../../images/Pendant1.png.png'),
  alt: 'Credit Card',
  start: ''
};

export const homeObjTwo = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline:
    'Interaction',
  description: "The interaction with the Elusive Pendant is straightforward and intuitive, combining vocal commands with touch gestures. To issue a command or make an inquiry, you simply Touch-and-Hold while speaking, releasing when finished. ",
  description1: "Your command or inquiry is promptly interpreted, followed by a vocal response or the initiation of a corresponding process.",
  imgStart: 'start',
  img: require('../../images/Pendant2.png'),
  alt: 'Vault',
  start: 'true'
};

export const homeObjThree = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Connectivity',
  headline1: 'Assistance',
  description:
    "Remain fully engaged in the present moment while staying effortlessly connected. Answering phone calls is as simple as a single tap, while a double tap declines or ends the call.",
  description0: "",//"Stay informed with vocal notifications, keeping you up-to-date with important alerts and messages without interruption.",
  description1: "The Elusive Pendant is imbued with Artificial Intelligence, providing an intriguing experience of assistance and engagement. With its advanced capabilities, it adeptly listens and comprehends your inquiries, standing ready to offer invaluable advice, pertinent information, and engaging conversation whenever you require.",
  description2: "With the ability to seamlessly access the vast expanse of the web, the pendant can promptly respond to inquiries regarding real-time information, ensuring each response is relevant to the current situation.",
  //description3: "Simply articulate your thoughts in natural language, and the pendant responds with immediate up-to-date insight and understanding.",
  start: ''
};

export const homeObjFour = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline:
    'Translation & Notes',
  headline1: 'Privacy',
  description:
    "Other aptitudes designed to enrich your daily routines include live translation and accurate transcription for note-taking, activated simply by issuing commands.",
  description0: "The Elusive Pendant is continuously evolving, thus expect more other capabilities to follow such as location awarness, calendar manipulation and email sending.",
  description1: "Elusive doesn’t retain by any means data regarding the interaction with the pendant except details referring to token usage.",
  description2: "The conversations, translations and notes are stored locally in the companion app, the most recent 30 entries for each.",
  imgStart: '',
  img: require('../../images/watch_circle.jpg'),
  alt: 'Vault',
  start: 'true'
};

export const sinusoidal3 = {
  identifier: "sinusoidal3",
  number: 3
};

export const sinusoidal1 = {
  identifier: "sinusoidal1",
  number: 1
};

export const sinusoidal2 = {
  identifier: "sinusoidal2",
  number: 2
};

export const sinusoidalFooter = {
  identifier: "sinusoidalFooter",
  number: 1
};