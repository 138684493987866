import { createGlobalStyle } from 'styled-components';

import MightyDiteyNF from './MightyDiteyNF.ttf';
import CooperHewittLight from './CooperHewittLight.otf'

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'MightyDiteyNF';
        src: local('MightyDiteyNF'),
        url(${MightyDiteyNF}) format('otf');
     
    }

    @font-face {
        font-family: 'HaloLight';
        src: local('HaloLight'),
        url(${CooperHewittLight}) format('otf');
     
    }
`;

export default FontStyles;