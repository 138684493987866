export const homeObjOne = {

  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  /*
      lightBg: false,
      lightText: true,
      lightTopLine: true,
      lightTextDesc: true,
    */
  topLine: 'Unisex',
  headline: 'Elusive Pendant',
  price: '80.00 EUR',
  price2: "+ 6.00 EUR/Month",
  description:
    'The Elusive Pendant is a sophisticated, intelligent wearable designed for everyday use, keeping you connected and supported by voice-activated advanced AI. Through its companion app, it seamlessly pairs with your Android smartphone, allowing you to stay engaged with your surroundings while reducing the need for screen interaction.',
  buttonLabel: 'Continue',
  imgStart: '',
  img: require('../../images/watch_first.jpg'),
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: '100% Secure',
  headline: 'Stay protected 24/7 anywhere anytime',
  description:
    'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  //img: require('../../images/svg-2.svg'),
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Easy Setup',
  headline: 'Super fast and simple onboarding process',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: require('../../images/watch_first.jpg'),
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/watch_first.jpg'),
  alt: 'Vault'
};