import styled, { createGlobalStyle } from 'styled-components';
import MightyDiteyNF from './fonts/MightyDiteyNF.ttf';
import CooperHewittLight from './fonts/CooperHewittLight.otf';


const GlobalStyles = createGlobalStyle`
:root {
  color-scheme: only light;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'CooperHewittLight', 'Source Sans Pro', sans-serif;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
*::-webkit-scrollbar {
  width: 0.5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
@font-face {
  font-family: 'MightyDiteyNF';
  src: url(${MightyDiteyNF});
}
@font-face {
  font-family: 'CooperHewittLight';
  src: url(${CooperHewittLight});
}

`;


export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? '#000' : '#fff')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: ${({ visible }) => (visible ? true : 'none')};
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
  
    background-color: ${({ primary }) => (primary ? '#000' : '#000')};
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);

  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Spacer = styled.div`
  height: 35px;
`;


export default GlobalStyles;   