import styled from 'styled-components';
import { Container } from '../../globalStyles'
import { Link } from 'react-router-dom'

export const Nav = styled.nav`
    //background: #ffffff;
    //background: radial-gradient(ellipse 80% 100% at left, rgba(196, 196, 196, 1.0), rgba(250, 250, 250, 0.95)); /* Light grey to white */
    //background: radial-gradient(ellipse 90% 100% at left, #dcdcdc, #fff)
    
    
    //background: linear-gradient(to bottom 50% 100%, rgba(216, 216, 216, 1.0), rgba(250, 250, 250, 0.95));
    //background: linear-gradient(-45deg, rgba(216, 216, 216, 1.0), rgba(250, 250, 250, 0.95));
    background: linear-gradient(-45deg, rgba(216, 216, 216, 1.0), rgba(250, 250, 250, 0.95));
    
    background-size: 200% 200%;
    //background-size: 200% 200%;
    //box-shadow: 0 2px 8px 0 rgba(10,10,10,.2);
    //height: 70px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    animation: pulse 2s ease infinite;
    @keyframes pulse {
        0% {
          box-shadow: 0 0 10px rgba(10, 10, 10, 0.5);
          //background: linear-gradient(to bottom, rgba(216, 216, 216, 1.0), rgba(250, 250, 250, 0.95));
          background-position: 100% 50%;

        }
        50% {
          box-shadow: 0 0 20px rgba(10, 10, 10, 0.7);
          //background: linear-gradient(to bottom, rgba(236, 236, 236, 1.0), rgba(250, 250, 250, 0.95));
          background-position: 0% 50%;

        }
        100% {
          box-shadow: 0 0 10px rgba(10, 10, 10, 0.5);
          //background: linear-gradient(to bottom, rgba(216, 216, 216, 1.0), rgba(250, 250, 250, 0.95));
          background-position: 100% 50%;

        }
      }



`

export const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    height: 80px;

    ${Container}
`

export const NavLogo = styled(Link)`
    color: #000;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
`

export const NavLogo1 = styled.div`
    font-family: 'MightyDiteyNF';
    font-size: 4.5rem;
`

export const HamburgerIcon = styled.div`
    display: none;
    font-weight: lighter;

    @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        font-weight: lighter;
        cursor: pointer;   
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    
  
    @media screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        opacity: 1;
        transition: all 0.5s ease;
        background: radial-gradient(ellipse 70% 60% at left, #dcdcdc, #fff);
        left: ${({ click }) => (click ? 0 : '-100%')};
    }
`
export const NavItem = styled.li`
    height: 70px;
    border-bottom: 2px solid transparent;
    border-radius: 2px;


    @keyframes fadeInBold {
        0% {
          opacity: 0.3;
          font-weight: normal;
        }
        100% {
          opacity: 1;
          font-weight: normal;
        }
      }

    &:hover {
        animation: fadeInBold 1.0s ease-in-out;

    }

    @media screen and (max-width: 960px) {
        width: 100%;

        &:hover {
            animation: fadeInBold 1.0s ease-in-out;

        }
    }

`

export const NavLinks = styled(Link)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    /* Your CSS file or style tag */

    @keyframes fadeInBold {
    0% {
        opacity: 0.3;
        font-weight: normal;
    }
    100% {
        opacity: 1;
        font-weight: normal;
    }
    }




    @media screen and (max-width: 960px) {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover {
            //font-weight: bold;
            animation: fadeInBold 1.0s ease-in-out;
        }
    }
`

export const NavItemBtn = styled.li`
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;