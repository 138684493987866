export const AboutUsDetails = {
    primary: true,
    lightBg: true,
    lightTopLine: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '  ',
    headline: 'Contact Us',
    description:
      "Your inquiries, feedback, or any other professional communication is here expected. Provide your name, email, and detailed message, followed by submition.",
    imgStart: '',
    img: require('../../images/watch_first.jpg'),
    alt: 'Credit Card',
    start: ''
  };
  
