import React from 'react';
import { Button } from '../../globalStyles';
import { ImgWrapper, Img } from './Footer.elements'


import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaPinterest
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';
import FooterSinusoidalCanvas from '../Sinusoidal/FooterSinusoidal';
import { sinusoidalFooter } from '../../pages/HomePage/Data';

function Footer() {

  const date = new Date();
  const img = require('../../images/banner_sal.png');
  const img1 = require('../../images/banner_sol.png');


  return (

    <FooterContainer>
      {/*
      <FooterSubscription>
        <FooterSubHeading>
          Join our exclusive membership to receive the latest news and trends
        </FooterSubHeading>
        <FooterSubText>You can unsubscribe at any time.</FooterSubText>
        <Form>
          <FormInput name='email' type='email' placeholder='Your Email' />
          <Button fontBig>Subscribe</Button>
        </Form>
      </FooterSubscription>
      */}


      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Information</FooterLinkTitle>
            <FooterLink to='/about-us'>About Us</FooterLink>
            <FooterLink to='/contact-us'>Contac Us</FooterLink>
            <FooterLink to='/'>Terms of Service</FooterLink>

            <ImgWrapper>
              <a href="https://anpc.ro/ce-este-sal/"> <Img src={img} /> </a>
            </ImgWrapper>
            <ImgWrapper >
              <a href="https://ec.europa.eu/consumers/odr/"><Img src={img1} /></a>
            </ImgWrapper>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>

      <SocialMedia>
        <SocialMediaWrap>
          <WebsiteRights>Copyright © {date.getFullYear()} Elusive. All rights reserved. </WebsiteRights>
          <SocialIcons>
            <SocialIconLink href='https://www.instagram.com/elusive_ro' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href='https://www.pinterest.com/elusive_ro' target='_blank' aria-label='Pinterest' >
              <FaPinterest />
            </SocialIconLink>
            {/*<SocialIconLink href='/' target='_blank' aria-label='YouTube' >
              <FaYoutube />
            </SocialIconLink>
    */}

          </SocialIcons>

        </SocialMediaWrap>
      </SocialMedia>


    </FooterContainer>
  );
}

export default Footer;