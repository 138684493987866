import React from 'react'
import { InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Price, PriceSub, TextColumn } from './SlideshowSection.elements'
import { Container, Button, Spacer } from '../../globalStyles'
import { Link } from 'react-router-dom'
import ImageSlideshow from './ImageSlideshow';
import List from './List';
import './ArrowButton.css';

const SlideshowSection = ({

    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    price,
    price2,
    img,
    alt,
    imgStart,
    start
}) => {
    const images = [
        require('../../images/watch_first.jpg'),
        'image3.jpg',
        // Add more image URLs
    ];
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <TextColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Price>{price}</Price>
                                <PriceSub>{price2}</PriceSub>
                                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                                <List></List>
                                <Spacer></Spacer>
                                <Link to='/sign-up'>
                                    <Button big fontBig visible={buttonLabel != null} primary={primary}>
                                        {buttonLabel}
                                    </Button>
                                </Link>
                            </TextWrapper>
                        </TextColumn>
                        <ImageSlideshow images={images} />
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default SlideshowSection;