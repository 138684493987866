import React, {useState, useEffect} from 'react'
import { Nav, 
    NavbarContainer, 
    NavLogo, 
    NavLogo1,
    HamburgerIcon,
    NavMenu,
    NavItem,
    NavLinks,
 } from './Navbar.elements'
import { IconContext } from 'react-icons/lib'
import HamburgerIcon1 from '../HamburgerIcon/HamburgerIcon1';


function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const [homeClick, setHomeClick] = useState(false);
    const [servicesClick, setServicesClick] = useState(false);
    const [productsClick, setProductsClick] = useState(false);


    const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = () => {
    handleClick();
    setIsOpen(!isOpen);
  };

    const handleHomeClick = () => {
        setHomeClick(true);
        setProductsClick(false);
        setServicesClick(false);
    }
    const handleServicesClick = () => {
        setHomeClick(false);
        setProductsClick(false);
        setServicesClick(true);
    }
    const handleProductsClick = () => {
        setHomeClick(false);
        setProductsClick(true);
        setServicesClick(false);
    }

    const handleClick = () =>  setClick(!click);
    
    const closeMobileMenu = () => {
        setClick(false);
        setIsOpen(false);
            
    }
    const showButton = () => {
        // so if the screensize is <= 960px then set button state to false
        if(window.innerwidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, [])

    window.addEventListener('resize', showButton);

    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/'> 
                        <NavLogo1>elusive</NavLogo1>
                    </NavLogo>
                    <HamburgerIcon>
                    <HamburgerIcon1 isOpen={isOpen} onClick={handleToggleMenu}/>
                    </HamburgerIcon>
                    <NavMenu onClick={handleClick} click={click} >
                        <NavItem onClick={handleHomeClick} homeClick={homeClick}>
                            <NavLinks to='/' onClick={closeMobileMenu}>
                                Home
                            </NavLinks>
                        </NavItem>

                        <NavItem onClick={handleServicesClick} servicesClick={servicesClick}>
                            <NavLinks to='/about-us' onClick={closeMobileMenu}>
                                About Us
                            </NavLinks>
                        </NavItem>
                    
                        {/*
                        <NavItem onClick={handleServicesClick} servicesClick={servicesClick}>
                            <NavLinks to='/services' onClick={closeMobileMenu}>
                                Services
                            </NavLinks>
                        </NavItem>
                    
                    
                        <NavItem onClick={handleProductsClick} productsClick={productsClick}>
                            <NavLinks to='/Products' onClick={closeMobileMenu}>
                                Products
                            </NavLinks>
                        </NavItem>
                        
                        <NavItemBtn >
                            {button ? (
                                <NavBtnLink to='/sign-up'>
                                    <Button primary>SIGN UP</Button>
                                </NavBtnLink>
                            ) : (
                                <NavBtnLink to='/sign-up'>
                                    <Button onClick={closeMobileMenu} fontBig primary>SIGN UP</Button>
                                </NavBtnLink>
                            )}
                          
                        </NavItemBtn>
                            */}
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>    
        </>
    )
}

export default Navbar
