import React, { useEffect } from 'react';
import { IconContext } from 'react-icons/lib'

const SinusoidalCanvas = ({identifier, number}) => {
  useEffect(() => {
    const canvas = document.getElementById(identifier);
    const ctx = canvas.getContext('2d');
    const numSinusoidals = number; // Adjust the number of sinusoidals
    const clearAlpha = 0.8;//0.85; // Adjust the alpha value for smoother clearing

    function drawSinusoidal(amplitude, frequency, yOffset, thickness, phase, startX) {
      const endX = canvas.width;
      const points = [];

      for (let x = startX; x <= endX; x++) {
        const y = amplitude * Math.sin(frequency * (x - phase)) + yOffset;
        points.push({ x, y });
      }

      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);

      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
      }

      ctx.lineWidth = thickness;
      ctx.strokeStyle = '#dcdcdc';
      ctx.stroke();
    }

    function drawMultipleSinusoidals() {
      const phaseShift = Date.now() * 0.0001; // Adjust the speed of the shift
      const verticalShift = 50;//Math.sin(phaseShift) * 50; // Adjust the amplitude of vertical shift

      // Clear the canvas with reduced alpha for smoother clearing
      ctx.fillStyle = `rgba(255, 255, 255, ${clearAlpha})`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < numSinusoidals; i++) {
        const amplitude = Math.random() * 30 + 20;
        const frequency = Math.random() * 0.05 + 0.002;
        const yOffset = 50 + (i / numSinusoidals) * canvas.height + verticalShift; // Apply vertical shift
        //const yOffset = 100;
        const thickness = Math.random() * 30 + 5;

        drawSinusoidal(amplitude, frequency, yOffset, thickness, phaseShift, 0);
      }
    }

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      drawMultipleSinusoidals();
    }

    window.addEventListener('resize', resizeCanvas);

    resizeCanvas();
    const intervalId = setInterval(drawMultipleSinusoidals, 300); // Adjust the interval for animation

    // Cleanup function to remove event listener and clear interval on component unmount
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ensures the useEffect runs only once

  return (        <IconContext.Provider value={{ color: '#fff' }}>

    <canvas id={identifier} style={{ display: 'block', height: '200px', width: '100%'}}></canvas>
    </IconContext.Provider>

  );
};

export default SinusoidalCanvas;

