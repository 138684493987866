import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './ImageSlideshow.css';
import './ArrowButton.css';

import { Img, ImgWrapper } from './SlideshowSection.elements';

const ImageSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSlide = (currentSlide + 1) % totalSlides;
      setCurrentSlide(nextSlide);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  const slides = [
    { src: require('../../images/watch_first.jpg'), alt: 'Image 1'},
    { src: require('../../images/watch_first.jpg'), alt: 'Image 2' },
    // Add more slide data as needed.
  ];
  const totalSlides = slides.length;

  const customArrowStyles = {
    // Define any custom styles for the arrows if needed
  };

  return (
    <div className="image-slideshow">
    
    <Carousel renderArrowPrev={(onClickHandler, hasPrev, label) =>
      hasPrev && (
        <div
          onClick={onClickHandler}
          title={label}
          style={{ ...customArrowStyles, display: 'none' }}
        >
          Previous
        </div>
      )
    }
    renderArrowNext={(onClickHandler, hasNext, label) =>
      hasNext && (
        <div
          onClick={onClickHandler}
          title={label}
          style={{ ...customArrowStyles, display: 'none' }}
        >
          Next
        </div>
      )
    } selectedItem={currentSlide} showThumbs={false} swipeable={true} // Enable touch interactions
    swipeScrollTolerance={10} renderThumbs={() => null}>
      {slides.map((slide, index) => (
        <div key={index}>
          <img src={slide.src} alt={slide.alt} />
        </div>
      ))}
      </Carousel> 
    </div>

  );
};

export default ImageSlideshow;


