import React from 'react'
import {InfoSec, InfoRow, InfoColumn, TextColumn, TextWrapper, TopLine, Heading, Subtitle} from './ContactUsSection.elements'
import { Container} from '../../globalStyles'
import ContactForm from './ContactForm'

const ContactUsSection = ({ 
    
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    secondDescription,
}) => {
    return (
        <>
        
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={false}>
                    <TextColumn>
            <TextWrapper>
                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
            </TextWrapper>
            </TextColumn>
                        <InfoColumn>
                        <ContactForm></ContactForm>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default ContactUsSection;