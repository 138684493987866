export const AboutUsDetails = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '  ',
  headline: 'About Us',
  description:
    "Elusive is a technology and lifestyle microbrand specialised in ambient computing with a particular focus on wearable devices. We consider that technology should seamlessly integrate into our lives, enhancing our experiences without introducing complexity and artificiality, but rather by augmenting our abilities.",
  secondDescription: "Join us on our journey as we redefine the intersection of technology and lifestyle. Experience the elegance, simplicity, and intelligence of Elusive.",
  thirdDescription: "#AI #avant-garde #minimalism",
  imgStart: '',
  img: require('../../images/watch_first.jpg'),
  alt: 'Credit Card',
  start: ''
};

