import React from 'react';
import { AboutUsDetails } from './Data';
import InfoInfoSection from '../../components/InfoInfoSection/InfoInfoSection';

const AboutUs = () => {
    return (
        <>
        <InfoInfoSection {...AboutUsDetails}></InfoInfoSection>
            
        </>
    )
}

export default AboutUs;