import React from 'react';
import InfoSectionBase from '../../components/InfoSection/InfoSectionBase';
import { InfoSection } from '../../components';
import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo, sinusoidal1, sinusoidal2, sinusoidal3 } from './Data';
import SinusoidalCanvas from '../../components/Sinusoidal/Sinusoidal';
import DoubleInfoSection from '../../components/DoubleInfoSection/DoubleInfoSection';

const Home = () => {
    return (
        <>

            <InfoSectionBase {...homeObjOne} />
            <SinusoidalCanvas {...sinusoidal3} />
            <InfoSection {...homeObjTwo} />

            <SinusoidalCanvas {...sinusoidal1} />
            <DoubleInfoSection {...homeObjThree} />
            <SinusoidalCanvas {...sinusoidal2} />
            <DoubleInfoSection {...homeObjFour} />
        </>
    )
}

export default Home;