import React from 'react'
import { InfoSec, InfoRow, TextColumn, TextWrapper, TopLine, Heading, Subtitle, InfoColumn } from './InfoInfoSection.elements'
import { Container } from '../../globalStyles'

const DoubleInfoSection = ({

    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    description1,
    description0,
    headline,
    headline1,
    description,
    description2,
    description3
}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={false}>
                        <TextColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                                <Subtitle lightTextDesc={lightTextDesc}>{description0}</Subtitle>

                            </TextWrapper>
                        </TextColumn>
                        <TextColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline1}</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>{description1}</Subtitle>
                                <Subtitle lightTextDesc={lightTextDesc}>{description2}</Subtitle>
                                <Subtitle lightTextDesc={lightTextDesc}>{description3}</Subtitle>
                            </TextWrapper>
                        </TextColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default DoubleInfoSection;