import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import GlobalStyles from './globalStyles'
import { Navbar, Footer } from './components';
import Home from './pages/HomePage/Home';
import Services from './pages/Services/Services';
import Products from './pages/Products/Products';
import SignUp from './pages/SignUp/SignUp';
import ScrollToTop from './components/ScrollToTop';
import Watch from './pages/Watch/Watch';
import AboutUs from './pages/AboutUs/AboutUs';
import { sinusoidalFooter } from './pages/HomePage/Data';
import FooterSinusoidalCanvas from './components/Sinusoidal/FooterSinusoidal';
import ContactUs from './pages/ContactUs/ContactUs';


function App() {
  return (

    <Router>
      <GlobalStyles />
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/watch' component={Watch} />
        <Route path='/services' component={Services} />
        <Route path='/products' component={Products} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/contact-us' component={ContactUs} />

      </Switch>
      <FooterSinusoidalCanvas {...sinusoidalFooter}></FooterSinusoidalCanvas>
      <Footer />
    </Router>


  );
}

export default App;
