import React from 'react';
import { homeObjOne } from './Data';
import SlideshowSection from '../../components/SlideshowSection/SlideshowSection';

function Watch() {

  const images = [
    require('../../images/watch_first.jpg'),
    'image3.jpg',
    // Add more image URLs
  ];

  return (
    <>
      <SlideshowSection {...homeObjOne} />
    </>
  );
}

export default Watch;